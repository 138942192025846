import reducePreloads from '@swe/shared/network/helpers/reduce-preloads';
import { useIntl } from '@swe/shared/tools/intl';

import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import { StoreAbout } from 'common/components/store-about';
import { getSeo } from 'common/use-cases/use-seo';
import { useStoreInfo } from 'common/use-cases/use-store-info';
import { GetShopInfoEndpoint } from 'endpoints/shop/get-shop-info';
import { ShopInfo } from 'entities/shop/info';

const AboutStorePage: ApplicationPage = () => {
  const { intl } = useIntl();
  const store = useStoreInfo();

  const location = [store?.location?.city?.name, store?.location?.region?.name].join(', ');
  const defaultTitle = `${store?.saleTypes?.join(' & ')} Dispensary ${location ? `In ${location}` : ''}`.trim();
  const title = intl.t('pages.aboutUs.heading', { location }, defaultTitle);

  return (
    <Page
      header={{
        title,
        hideBackButton: true,
        breadcrumbs: [{ text: intl.t('pages.aboutUs.breadcrumbTitle', undefined, 'About us') }],
      }}
    >
      {store && <StoreAbout {...store} />}
    </Page>
  );
};

AboutStorePage.getMeta = ({ queryClient, dealerName }) => {
  const storeInfo = queryClient.getQueryData(GetShopInfoEndpoint.key())! as ShopInfo;
  const { builder, payload } = getSeo(dealerName, storeInfo);
  const { name, cityName, saleTypesFormatted, fulfillmentTypesFormatted, address } = payload;

  builder.title.add([`Welcome to ${name} in ${cityName}`, 'Store hours', 'Contacts']);
  builder.description.add([`Welcome to ${name} in ${cityName}`, saleTypesFormatted, ...fulfillmentTypesFormatted]);
  builder.keywords.add([cityName, name, `Cannabis store in ${cityName}`, `address ${address}`]);

  return builder.toProps();
};

AboutStorePage.preload = async ({ headers, queryClient }) => {
  return reducePreloads([GetShopInfoEndpoint.preload(undefined, { headers }, queryClient)]);
};
AboutStorePage.getMainLayoutProps = () => ({ size: 'md', centered: true });

export default AboutStorePage;
